<template>
    <a href="javascript:void(0);"
        data-toggle="popover"
        title="Verfügbare Sonderangebote"
        data-trigger="click"
        data-placement="top"
        data-html="true"
        :data-content="getOfferText">
        <span class="offer-left">{{ voMsg('search.offers.available') }}</span>
    </a>


</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    import {
    	getVoucher
    }
    from 'voUtils/VoucherStore.js';

    export default {
    	voVueComponent: 'offer-help',
    	props: {
    		offers: Array
    	},
    	mounted: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    		$('body').on('click', function(e) {
    			$('[data-toggle=popover]').each(function() {
    				if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
    					(($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false;
    				}
    			});
    		});
    	},
    	methods: {
    		getVoucherCode: function() {
    			var voucher = getVoucher();
    			if (voucher) {
    				return voucher.code;
    			}
    			return null;
    		},
    		checkOfferTime: function(offer) {

    			var fromdate = new Date(offer.fromdate);
    			var tilldate = new Date(offer.tilldate);
    			var today = new Date();
    			//console.log('fromdate::' + fromdate);
    			//console.log('todate::' + tilldate);
    			//console.log('today::' + today);
    			if (fromdate && tilldate && tilldate >= today && fromdate <= today) {
    				return true;
    			}
    			/*if (offer.type === 'LASTMINUTE') {
    				return true;
    			} else {
    				return true;
    			}*/
    			return false;

    		}

    	},
    	computed: {

    		getOfferText: function() {
    			var offerText = '';
    			var cur = VOFFICE.currency || '&euro;';
    			if (this.offers) {
    				this.offers.sort((a, b) => (a.fromdate > b.fromdate) ? 1 : -1);
    				var i;
    				offerText = "<ul>";
    				for (i = 0; i < this.offers.length; i++) {
    					var offer = this.offers[i];
    					if (!offer.voucherCode || offer.voucherCode === this.getVoucherCode()) {
    						if (this.checkOfferTime(offer)) {
    							var offer = this.offers[i];

    							offerText += "<li>";

    							offerText += offer.name + " ";
    							if (offer.discountType === 'PERCENT' && offer.type != 'LASTMINUTE') {
    								offerText += "- " + offer.value / 100 + "% " + this.voMsg('tpl.text.rabatt') + " ";
    							}

    							if (offer.discountType === 'FLAT') {
    								offerText += "- " + offer.value / 100 + " " + cur + " " + this.voMsg('tpl.text.rabatt') + " ";
    							}

    							if (offer.type === 'EARLYBIRD') {
    								if (offer.timeSpan === 1) {
    									offerText += this.voMsg('tpl.offer.earlybird.arrival') + " ";
    								} else if (offer.timeSpan > 1) {
    									offerText += this.voMsg('tpl.offer.earlybird.arrival.pl') + " " + offer.timeSpan + " " + this.voMsg('tpl.offer.earlybird.days') + " ";
    								}

    							}

    							/*if (offer.type === 'LASTMINUTE') {
    								if (offer.timeSpan == 1) {
    									offerText += this.voMsg('tpl.search.offer.lastminute.arrival') + " ";
    								} else if (offer.timeSpan > 1) {
    									offerText += this.voMsg('tpl.search.offer.lastminute.arrival.pl') + " " + offer.timeSpan + " " + this.voMsg('tpl.text.days') + " ";
    								}

    							}*/


    							/*if (offer.fromdate) {
    								offerText += this.voMsg('tpl.text.from') + " " + DateEx.formatDate(this.offers[i].fromdate, undefined, VOFFICE.mlang) + " ";
    							}
    							if (offer.tilldate) {
    								offerText += this.voMsg('tpl.text.until') + " " + DateEx.formatDate(this.offers[i].tilldate, undefined, VOFFICE.mlang) + " ";
    							}*/

    							if (offer.minStay > 1 && offer.type != 'LASTMINUTE') {
    								offerText += "(" + this.voMsg('tpl.min') + " " + offer.minStay + " " + this.voMsg('tpl.text.nights') + ")";
    							}

    							offerText += "</li>";
    						}
    					}
    				}
    			}

    			return offerText;
    		}
    	}
    };
</script>